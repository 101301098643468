<template>
  <div style="width: 100% ;padding: 10px">
    <div style="margin: 10px 0">
      <div class="block">
        <span class="demonstration"></span>
        <el-date-picker
            v-model="value2"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions">
        </el-date-picker>
        <el-button type="primary" style="margin-left:5px" @click = "selectkey">查询</el-button>
        <el-button type="primary" @click="remove">重置</el-button>
      </div>
    </div>
    <el-table
        :data="tableData"
        border
        style="width: 100%">
      <el-table-column
          prop="xinghao"
          label="型号">
      </el-table-column>
      <el-table-column
          prop="tuzhihao"
          label="图纸号">
      </el-table-column>
      <el-table-column
          prop="xiaoliang"
          label="销量">
      </el-table-column>
    </el-table>
    <div style="margin: 10px 0">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage4"
          :page-sizes="[5, 10, 20]"
          :page-size="10"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>

import request from "@/utils/request";

export default {
  data() {
    return {
      search:'',
      currentPage4:1,
      total:10,
      tableData: [],
      value: '',
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      value2: ''
    }
  },
  //加载表格
  mounted() {
    this.select()
  },
  methods:{
    //整表查询
    select(){
      /*request.post("/connect/select").then(res =>{
        this.tableData = res.data
      })*/
    },
    //关键字查询
    selectkey(){
      if (parseInt(this.search) >0 ){
        request.post("/connect/selectid",parseInt(this.search)).then(res =>{
          this.tableData = res.data
        })
      } else {
        request.post("/connect/selectkey",this.search).then(res =>{
          this.tableData = res.data
        })
      }
    },
    //重置
    remove(){
      this.value2=''
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
  }
}

</script>

<style scoped>

</style>